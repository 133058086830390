/*************************************************************************************
* dateCheck
*************************************************************************************/
export default (function() {
	const _clear = function(){
		$(this).closest('.dateCheck').find('[type=checkbox]').prop('checked', false)
	}

	return {
		clear: _clear,
	};
}());
