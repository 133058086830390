/*************************************************************************************
* gNav
*************************************************************************************/
import overlay from './_overlay.js';
import pcsp from './_pcsp.js';

export const showHide = (function() {
	const _init = (args) =>{}
	const _open = () =>{
		$('.gNav').switchClass('gNav-isClose', 'gNav-isOpen');
	}
	const _close = () =>{
		if ($('.gNav').hasClass('gNav-isOpen')) {
			$('.gNav').switchClass('gNav-isOpen', 'gNav-isClose');
			$('.gHeader_gNavBtn').switchClass('gHeader_gNavBtn-isOpen', 'gHeader_gNavBtn-isClose');
			overlay.close();
		}
	}
	const _toggle = () =>{
		if ($('.gNav').hasClass('gNav-isOpen')) {
			$('.gNav').switchClass('gNav-isOpen', 'gNav-isClose');
			$('.gHeader_gNavBtn').switchClass('gHeader_gNavBtn-isOpen', 'gHeader_gNavBtn-isClose');
		} else {
			$('.gNav').switchClass('gNav-isClose', 'gNav-isOpen');
			$('.gHeader_gNavBtn').switchClass('gHeader_gNavBtn-isClose', 'gHeader_gNavBtn-isOpen');
		}
		overlay.toggle();
	}

	return {
		init: _init,
		open: _open,
		close: _close,
		toggle: _toggle,
	};
}());

export const dropDown = (function(){
	const _init = function() {
		pcsp.init();
		$('.gNav_sub').attr('style', '');
		$('.gNav_sub').each(function(index, el) {

			$(this).attr('data-max-h', $(this).outerHeight());

			$(this).css({
				visibility: 'visible'
			})
			if (pcsp.getMode() === 'pc') {
				$(this).css({
					maxHeight: 0
				})
			} else {
				var _$root = $(this).closest('.gNav_item').find('.gNav_toggleBtn');
				if (_$root.hasClass('gNav_toggleBtn-isOpen')) {
					$(this).css({
						maxHeight: $(this).attr('data-max-h') + 'px'
					})
				} else {
					$(this).css({
						maxHeight: 0
					})
				}
			}
		});

	}
	const _open = function() {
		var _$root = $(this).closest('.gNav_item');
		var maxH = _$root.find('.gNav_sub').attr('data-max-h');
		_$root.find('.gNav_sub').css({
			maxHeight: maxH + 'px',
		})
		_$root.find('.gNav_toggleBtn').addClass('gNav_toggleBtn-isOpen');
		// console.log(maxH);
		// console.log(_$root.find('.gNav_sub'));
	}

	const _close = function(){
		var _$root = $(this).closest('.gNav_item');
		_$root.find('.gNav_sub').css({
			maxHeight: 0
		})
		_$root.find('.gNav_toggleBtn').removeClass('gNav_toggleBtn-isOpen');
	}

	const _toggle = function(){
		var _$root = $(this).closest('.gNav_item');
		if (_$root.find('.gNav_toggleBtn').hasClass('gNav_toggleBtn-isOpen')) {
			_close.call($(this));
		} else {
			_open.call($(this));
		}
	}

	return {
		init: _init,
		open: _open,
		close: _close,
		toggle: _toggle,
	};
}())