/*************************************************************************************
* anchorJump
*************************************************************************************/
import pcsp from './_pcsp.js';

export default (function() {
	var _hash = location.hash
	var _jump = function(args) {
		pcsp.init(function(){
			if (_hash) {
				if (pcsp.getMode() === 'sp') {
					$(window).scrollTop($(_hash).offset().top - 73);
				} else {
					$(window).scrollTop($(_hash).offset().top);
				}
			}
		});
	}
	return {
		init: _jump,
	};
}());
