/*************************************************************************************
* subjectCheck
*************************************************************************************/
export default (function() {
	const _clear = function(){
		$(this).closest('.subjectCheck').find('[type=checkbox]').prop('checked', false)
	}

	return {
		clear: _clear,
	};
}());
