$.fn.extend({
	switchClass : function(before, after) {
		$(this).removeClass(before)
		$(this).addClass(after)
	},
})

//plugins
import './_plugins/_lightgallery/_lightgallery.min.js';
import './_plugins/_lightgallery/_lg-thumbnail.min.js';
import './_plugins/_lightgallery/_lg-video.min.js';
import './_plugins/_lightgallery/_lg-zoom.min.js';

import './_plugins/_jquery.matchHeight-min.js';
import './_plugins/_slick.min.js';


//_modules
import resizeend from './_modules/_resizeend.js';
import pcsp from './_modules/_pcsp.js';
import anchorJump from './_modules/_anchorJump.js';
import accordion from './_modules/_accordion.js';
import smoothScroll from './_modules/_smoothScroll.js';
import * as gNav from './_modules/_gnav.js';
import overlay from './_modules/_overlay.js';
import subjectCheck from './_modules/_subjectCheck.js';
import dateCheck from './_modules/_dateCheck.js';

$(function(){
	resizeend.init();
	/*************************************************************************************
	* window load
	*************************************************************************************/
	$(window).on('load', function(event) {
		anchorJump.init();
	});

	/*************************************************************************************
	* window resize
	*************************************************************************************/
	$(window).on('load resizeend', function(event) {
		pcsp.judge();
		$.fn.matchHeight._update();
		accordion.init();
		gNav.dropDown.init();
	});

	/*************************************************************************************
	* pcspのbreakpointが切り替わったとき
	*************************************************************************************/
	$(window).on('pcsp.changed', function(event, mode) {
		// console.log(pcsp.getMode())
		gNav.showHide.close();
		gNav.dropDown.init();
		overlay.close();
	});

	/*************************************************************************************
	* クリックイベント
	*************************************************************************************/
	$(document).on('click', '[href^=#]', function(event) {
		event.preventDefault();
		smoothScroll.scroll.call($(this))
	});
	$(document).on('click', '[data-acc-btn], [data-acc-close]', function(event) {
		event.preventDefault();
		accordion.toggle.call($(this))
	});

	//gNav
	$(document).on('click', '.gHeader_gNavBtn', function(event) {
		event.preventDefault();
		gNav.showHide.toggle();
	});

	$(document).on({
		'mouseenter': function(){
			if (pcsp.getMode() === 'sp') { return false }
			gNav.dropDown.open.call($(this))
		},
		'mouseleave': function(){
			if (pcsp.getMode() === 'sp') { return false }
			gNav.dropDown.close.call($(this))
		}
	}, '.gNav_item');
	$(document).on('click', '.gNav_toggleBtn', function(event) {
		event.preventDefault();
		if (pcsp.getMode() === 'pc') { return false }
		gNav.dropDown.toggle.call($(this))
	});


	//overlay
	$(document).on('click', '.overlay', function(event) {
		event.preventDefault();
		overlay.close();
		gNav.showHide.close();
	});

	//dateCheck
	$(document).on('click', '.dateCheck_clear > a', function(event) {
		event.preventDefault();
		dateCheck.clear.call($(this))
	});

	//subjectCheck
	$(document).on('click', '.subjectCheck_clear > a', function(event) {
		event.preventDefault();
		subjectCheck.clear.call($(this))
	});

	/*************************************************************************************
	* その他イベント
	*************************************************************************************/

	/*************************************************************************************
	* プラグイン
	*************************************************************************************/
	//modal
	$('[data-modal]').lightGallery({
		selector: 'this',
		download: false,
		counter: false,
		zoom: true,
	});

	//modal-gallery
	$('[data-modal-gallerys]').lightGallery({
		selector: $(this).find('[data-modal-gallery]'),
		download: false,
		counter: true,
		zoom: true,
	});

})